<template>
    <div class="page-container">
        <div class="search-container">
            <search-v1
                    class="search"
                    v-model="keyword"
                    @search="workerList"
                    @clear="workerList"
                    placeholder="请输入要搜索的手机号码"
            ></search-v1>
        </div>
        <div class="user-list">
            <div :class="[searchUserId == user.user_id ? 'user-info-active' : '']" class="user-info"
                 v-for="(user,index) in userList" :key="'users-'+index">
                <div class="infos">
                    <div class="avatar">
                        <img :src="user.avatarUrl"/>
                    </div>
                    <div class="info">
                        <div class="nickName">{{user.real_name}}</div>
                        <div class="mobile">{{user.mobile ? user.mobile : '未设置手机号码'}}</div>
                    </div>
                    <div class="remove-button" @click="removeWorker(user.worker_id)">删除成员</div>
                </div>
                <div class="line"></div>
            </div>
        </div>

        <nut-popup v-model="show" position="center"
                   :style="{ height: '170px',width:'75%',borderRadius:'5px' }"
                   :close-on-click-overlay="false">
            <div class="popup-window">
                <div class="content">确定要删除这个员工吗？</div>
                <div class="popup-buttons">
                    <div class="cancel" @click="cancel">取消</div>
                    <div class="ok" @click="doRemoveWorker">确定</div>
                </div>
            </div>
        </nut-popup>
    </div>
</template>

<script>
  import SearchV1 from '@/template/community/search/searchV1'

  export default {
    name: 'search-user',
    components: {SearchV1},
    data () {
      return {
        keyword: '',
        userList: [],
        searchUserId: 0,
        show: false
      }
    },
    mounted () {
      document.title = '自提点员工列表'
      this.keyword = this.$route.query?.keyword || ''
      this.workerList()
    },
    methods: {
      workerList () {
        let params = {
          keyword: this.keyword
        }
        this.$api.community.points.pointWorkerList(params).then(response => {
          this.userList = response.data
        })
      },
      removeWorker (user_id) {
        this.searchUserId = user_id
        this.show = true
      },
      cancel () {
        this.searchUserId = 0
        this.show = false
      },
      doRemoveWorker () {
        let params = {
          remove_worker_id: this.searchUserId
        }
        this.$api.community.points.pointRemoveWorker(params).then(response => {
          this.$toast.text(response.message)
          this.show = false
          if (response.error == 0) {
            this.workerList()
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
    .popup-window {
        display: flex;
        flex-direction: column;

        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #303030;
            padding: 45px 0;
        }

        .popup-buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0 15px;

            .cancel {
                width: 118px;
                height: 30px;
                border-radius: 15px;
                border: 1px solid #979797;
                font-size: 12px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #666666;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .ok {
                width: 118px;
                height: 30px;
                background: #F46A17;
                border-radius: 15px;
                font-size: 12px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .page-container {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;

        .search-container {
            padding: 5px 15px;

            .search {
                height: 36px;
            }
        }

        .buttons {
            height: 90px;
            display: flex;
            align-items: center;
            justify-content: center;

            .button {
                width: 275px;
                height: 45px;
                background: linear-gradient(139deg, #FAC484 0%, #F46A17 100%);
                box-shadow: 0px 1px 6px 0px rgba(244, 106, 23, 0.55);
                border-radius: 23px;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .user-list {
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow-y: scroll;

            .user-info {
                display: flex;
                flex-direction: column;
                padding: 0 15px;

                .line {
                    height: 1px;
                    background: #F2F2F2;
                    margin-top: 10px;
                }

                .infos {
                    display: flex;
                    flex-direction: row;
                    padding: 5px 0;
                    align-items: center;
                    margin-top: 10px;

                    .avatar {
                        width: 48px;
                        height: 48px;
                        -webkit-border-radius: 48px;
                        -moz-border-radius: 48px;
                        border-radius: 48px;
                        overflow: hidden;
                        margin-right: 10px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .info {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        padding: 5px 0;

                        .nickName {
                            flex: 1;
                            font-size: 14px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #303030;
                            display: flex;
                            align-items: center;
                        }

                        .mobile {
                            font-size: 11px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #666666;
                        }
                    }

                    .remove-button {
                        width: 88px;
                        height: 28px;
                        background: #EDEDED;
                        border-radius: 14px;
                        font-size: 12px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #979797;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

            }

            .user-info-active {
                background: rgba(0, 0, 0, 0.1);
            }
        }
    }

</style>